$primary: #007bff;

.showPdf {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    height: 100vh;
    width: 100vw;
    overflow: scroll;
    display: none;
    cursor: pointer;

    &:before {
        content: "\2715";
        top: 0.3rem;
        right: 1rem;
        color: white;
        position: fixed;
        font-size: 2.5rem;
    }

    &.show {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    iframe {
        width: 90vw;
        height: 90vh;
        cursor: initial;
    }
}

.avatar {
    width: 150px;
    height: 150px;
	border-radius: 50%;
	overflow: hidden;

    img {
        width: 150px;
        height: 150px;
        object-fit: cover;
    }

    &__input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

    }

	.no-avatar {
		color: $primary;
		border: solid 2px $primary;
		width: 98%;
		height: 98%;
		border-radius: 50%;
		aspect-ratio: 1;
		display: grid;
		align-items: center;
		justify-items: center;
		user-select: none;

		> span {
			font-size: 4rem;
			font-weight: 200;
		}
	}
}

.currentAvatar {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;

    .currentAvatar__close {
        font-size: 2rem;
        position: absolute;
        margin-top: -1rem;
        cursor: pointer;
    }

    .currentAvatar__image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.pills {
	text-align: center;

	a.btn {
		box-shadow: none !important;
	}
}

.editor, .signature {
	background: rgba(0,0,0,0.1);
	padding: 1rem 2rem !important;
	display: block;

	.ce-paragraph {
		margin: 1rem 0;
	}
}